import React from "react";

export default function SignUpConsent() {
  return (
    <>
      <article>
        <p>
          Добро пожаловать на услуги Копирования торговли, принадлежащие и
          управляемые компанией FITT ("Компания", "мы", "наша" или "наш"). Эти
          Условия копирования торговли ("Условия копирования торговли")
          составляют юридически обязательное соглашение между Компанией и
          Копировщиком (каждый - "Пользователь", "вы" или "ваш") услуг
          Копирования торговли.
        </p>
        <p>
          Компания является "Оператором" в рамках этих Условий копирования
          торговли. Вы признаете и соглашаетесь с тем, что: (i) Компания
          предоставляет только технологическую услугу, и вы можете использовать
          услуги, предоставляемые третьими сторонами, такими как ликвидность,
          исполнение и торговые услуги; и (ii) область отношений между вами и
          Компанией должна быть в рамках технологических и программных услуг.
        </p>
        <p>
          ПОЛУЧАЯ ДОСТУП И ИСПОЛЬЗУЯ УСЛУГИ Fitt, ВЫ ЗАЯВЛЯЕТЕ И ГАРАНТИРУЕТЕ,
          ЧТО НЕ ВКЛЮЧЕНЫ В ЛЮБОЙ СПИСОК ТОРГОВЫХ ЭМБАРГО ИЛИ ЭКОНОМИЧЕСКИХ
          САНКЦИЙ (НАПРИМЕР, СПИСОК САНКЦИЙ СОВЕТА БЕЗОПАСНОСТИ ООН), СПИСОК
          ОСОБО УКАЗАННЫХ ЛИЦ, СОХРАНЯЕМЫХ OFAC (УПРАВЛЕНИЕ ПО ВНЕШНИМ АКТИВАМ
          СЕКРЕТАРИАТА ФИНАНСОВОГО ВЕДОМСТВА США) ИЛИ СПИСОК ОТКАЗНЫХ ЛИЦ ИЛИ
          ЮРИДИЧЕСКИХ ЛИЦ, СОСТАВЛЯЕМЫЙ ДЕПАРТАМЕНТОМ КОММЕРЦИИ США. КОМПАНИЯ
          ОСТАВЛЯЕТ ЗА СОБОЙ ПРАВО ВЫБОРА РЫНКОВ И ЮРИСДИКЦИЙ ДЛЯ ОСУЩЕСТВЛЕНИЯ
          БИЗНЕСА И МОЖЕТ ОГРАНИЧИВАТЬ ИЛИ ОТКАЗЫВАТЬ В ПРЕДОСТАВЛЕНИИ УСЛУГ В
          НЕКОТОРЫХ СТРАНАХ И РЕГИОНАХ И СВОИМ ПОЛЬЗОВАТЕЛЯМ.
        </p>
        <ol>
          <li>
            Принятие и изменение условий копирования сделок
            <ol>
              <li>
                Пожалуйста, внимательно ознакомьтесь с данными условиями
                копирования сделок. Если вы становитесь копи-трейдером, вы
                считаетесь прочитавшим, принявшим и согласившимся быть связанным
                данными условиями копирования сделок. Мы можем изменять или
                дополнять данные условия в любое время по нашему единоличному и
                абсолютному усмотрению. Любые изменения этих условий вступают в
                силу с даты «ПОСЛЕДНЕГО ИЗМЕНЕНИЯ», указанной вверху этой
                страницы. Вы признаете и соглашаетесь с тем, что форма и
                характер копирования сделок и любая ее часть могут меняться
                время от времени без предварительного уведомления вам, и что мы
                можем добавлять новые или удалять существующие функции и менять
                любую часть услуг копирования сделок. Вы несете ответственность
                за периодическую проверку этих документов, чтобы ознакомиться с
                их текущей версией. Ваше дальнейшее доступ и использование услуг
                копирования сделок будет рассматриваться как принятие условий
                копирования сделок.
              </li>
              <li>
                Если какое-либо положение данных Условий копирования сделок или
                любые будущие изменения в этих документах не принимаются вами,
                не используйте или продолжайте использовать услуги копирования
                сделок. Ваше дальнейшее использование услуг копирования сделок
                после размещения уведомления об изменении этих Условий будет
                рассматриваться как ваше согласие и соглашение с таким
                изменением.
              </li>
              <li>
                УВЕДОМЛЕНИЕ О РАЗРЕШЕНИИ СПОРОВ: ЭТИ УСЛОВИЯ СОДЕРЖАТ КЛАУЗУ ОБ
                АРБИТРАЖЕ. ЗА ИСКЛЮЧЕНИЕМ ОПРЕДЕЛЕННЫХ ВИДОВ СПОРОВ, УКАЗАННЫХ В
                ЭТОЙ КЛАУЗЕ ОБ АРБИТРАЖЕ, ВЫ И КОМПАНИЯ СОГЛАШАЕТЕСЬ НА
                РАЗРЕШЕНИЕ СПОРОВ ОБЯЗАТЕЛЬНЫМ ХОЗЯЙСТВЕННЫМ АРБИТРАЖЕМ И
                ОТКАЗЫВАЕТЕСЬ ОТ ЛЮБОГО ПРАВА УЧАСТВОВАТЬ В КОЛЛЕКТИВНЫХ ИСКАХ
                ИЛИ КОЛЛЕКТИВНЫХ АРБИТРАЖАХ.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Обзор Fitt
            <ol>
              <li>
                Fitt предоставляет вам возможность взаимодействовать, следить и
                копировать портфели других трейдеров. Он позволяет пользователям
                анализировать финансовые данные, сравнивать и копировать сделки
                и техники. Вы сможете принимать инвестиционные решения по
                криптоактивам на основе информации, предоставленной "лидерами
                трейдеров" (которые могут быть анонимными или неустановленными),
                такой как детали сделок, совершенных ими на платформе. Тем не
                менее, Fitt не был разработан для предоставления
                персонализированных инвестиционных рекомендаций, инвестиционных
                советов, налоговых рекомендаций или других финансовых
                рекомендаций любого вида. Любая информация, связанная с
                копированием сделок или о производительности копирования сделок,
                не должна рассматриваться как инвестиционный совет. Вся
                информация, доступная на платформе, предоставляется нами
                исключительно для информационных целей, и такая информация
                должна использоваться в качестве отправной точки для вашего
                собственного независимого исследования и принятия инвестиционных
                решений. Fitt не должен рассматриваться как советы или
                рекомендации или одобрения от имени Компании и/или ее
                аффилированных лиц.
              </li>
              <li>
                Компания имеет право оценивать ваше знание, опытность,
                приспособляемость и оценить, является ли Fitt подходящей
                инвестицией для вас. Эта оценка будет определяться информацией и
                документами, предоставленными вами, и вы можете ее обновлять в
                любое время. Компания не будет нести ответственность за ваши
                потери в результате предоставления ложной или обманчивой
                информации в рамках вашей оценки.
              </li>
              <li>
                Копирование сделок предоставляет только услугу копирования,
                отправляя синхронизированный с рынком ордер трейдера с
                авторизацией пользователя на проведение сделки на контрагентном
                рынке. Нет гарантии, что скопированные ордера будут иметь
                одинаковую цену открытия/закрытия, время открытия/закрытия и
                соотношение прибыли/убытка из-за изменений в рыночных условиях,
                изменений в глубине рынка и задержек выполнения инструкций
                системы.
              </li>
              <li>
                Мы будем следить за производительностью любого пользователя, и
                оставляем за собой право по своему усмотрению: (i) открывать и
                закрывать пользовательские аккаунты; (ii) включать или исключать
                лидеров торговли, копирующих трейдеров и/или портфели; (iii)
                блокировать, останавливать или приостанавливать копирование
                любого лидера торговли и/или любого портфеля на платформе; (iv)
                отменять или отклонять любые ордера, размещенные любым третьим
                лицом от вашего имени по соображениям безопасности и/или
                законных причин; (v) прекращать отношения с вами.
                <ol>
                  <li>
                    Для пользователей FItt: (ii) Пользователь FItt соглашается
                    соблюдать правила платформы Fitt, такие как: (a)
                    недоступность функций Copy Trading; (b) отмена отношений с
                    лидерами торговли; (c) ограничение на количество лидеров
                    торговли, которых можно копировать; (d) ограничение на
                    создание портфелей; (e) ограничение на минимальный и
                    максимальный депозит на портфель; (f) ограничение на
                    максимальное количество портфелей для копирования; (g)
                    волатильность цены из-за ликвидности биржевой торговли.
                    (iii) Пользователь FItt также соглашается с тем, что
                    платформа Fitt имеет право на регулировку некоторых функций,
                    включая, но не ограничиваясь: (a) публичной торговой
                    информации пользователя FItt; (b) цикла расчета доли прибыли
                    пользователя FItt; (c) скрытия некоторой и/или всех ключевых
                    сведений, относящихся к ордерам копирования на странице
                    позиции пользователя FItt. (iv) Пользователь FItt признает и
                    соглашается с тем, что размещение ордера на копирование
                    лидера торговли или других инвесторов на платформе Fitt
                    может привести к риску убытков, и весь счет может потерять
                    деньги, которые должен нести исключительно пользователь
                    FItt.
                  </li>
                </ol>
              </li>
              <li>
                В случае несоблюдения пользователем FItt условий, могут
                применяться следующие ограничения в зависимости от тяжести
                нарушений: (a) предупреждение; (b) снижение рейтинга сообщества;
                (c) снижение вашего максимального плеча и/или максимального
                объема торгов; (d) невозможность использования услуг Fitt; (e)
                отсутствие выплаты доли прибыли, заработанной нарушающей
                торговлей; (f) постоянная блокировка учетной записи.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Заявления и гарантии
            <ol>
              <li>
                При использовании услуг FItt вы заявляете и гарантируете, что:
                <p className="mb-0">
                  (i) У вас есть возможность заключить соглашение FItt;
                </p>
                <p className="mb-0">
                  (ii) У вас есть все необходимые согласия и полномочия для
                  заключения соглашения FItt и использования услуг FItt;
                </p>
                <p className="mb-0">
                  (iii) Вы понимаете и соглашаетесь с тем, что вы можете быть
                  зарегистрированы как KYC-лицо, что может включать политически
                  значимые лица и проверку на наличие санкций;
                </p>
                <p className="mb-0">
                  (iv) Вы признаете и соглашаетесь на предоставление личной
                  информации и документов, которые являются правдивыми,
                  надежными, точными, полными и не вводящими в заблуждение;
                </p>
                <p className="mb-0">
                  (v) Вы понимаете, что необходимо сохранять конфиденциальность
                  информации об учетной записи (например, идентификатор
                  пользователя, пароль, персональные данные и т.д.) и несете
                  единоличную ответственность за ее раскрытие;
                </p>
                <p className="mb-0">
                  (vi) Вы действуете от своего имени и в своих интересах, а не
                  как агент, адвокат, доверенное лицо или представитель
                  какого-либо другого лица;
                </p>
                <p className="mb-0">
                  (vii) Вы не будете использовать информацию третьих лиц
                  (например, имя, идентификационный номер, номер телефона, О
                  электронной почты). Если предоставленная информация
                  недостоверна, незаконна или недействительна, Компания запретит
                  вам использование платформы FItt;
                </p>
                <p className="mb-0">
                  (viii) Вы соглашаетесь обновлять свою личную информацию и в
                  случае изменения типа учетной записи, вы соглашаетесь
                  обратиться в службу поддержки клиентов, подав заявку на
                  изменение. Если вы не своевременно уведомите Компанию об
                  изменении личной информации, Компания не будет нести
                  ответственности за любые убытки, вызванные изменением.
                </p>
                <p className="mb-0">
                  (ix) Вы понимаете и соглашаетесь с тем, что должны сообщать
                  платформе FItt об ошибках, которые произошли в течение
                  семидесяти двух (72) часов после выполнения сделок.
                </p>
                <p className="mb-0">
                  (x) Вы не загружали и не передавали вредоносный код на
                  платформу FItt или иным образом не использовали любые
                  электронные устройства, программное обеспечение, алгоритмы
                  и/или методы или стратегии, которые направлены на манипуляцию
                  любым аспектом платформы FItt или услугами FItt;
                </p>
                <p className="mb-0">
                  (xi) Вы соглашаетесь публиковать в платформе FItt только
                  информацию, которая соответствует соответствующим законам и
                  правилам. Вы не будете злонамеренно публиковать или
                  распространять плохую информацию, которая нарушает законы и
                  правила и может быть недостойной, включая, но не ограничиваясь
                  текстом, изображениями, аудио, видео и т. д.
                </p>
                <p className="mb-0">
                  (xii) Вы будете инвестировать только те деньги через услуги
                  FItt, которые не связаны с торговлей наркотиками, похищениями,
                  террористической деятельностью или какой-либо другой
                  преступной деятельностью, которая незаконна или может быть
                  признана незаконной каким-либо соответствующим органом и/или
                  юрисдикцией;
                </p>
              </li>
              <li>
                В случае нарушения каких-либо заявлений или гарантий, данного
                Соглашения: (i) Компания может закрыть любые ордера или сделки,
                которые вы сделали, и/или закрыть или заморозить вашу учетную
                запись; (ii) вы несете единоличную ответственность за все потери
                и убытки, вызванные таким нарушением.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Риски и условия использования
            <ol>
              <li>
                Криптоактивы являются виртуальными продуктами с высоким риском
                быстрой потери денег. Цены могут значительно колебаться в любой
                день. Из-за таких изменений цены, ваш портфель может значительно
                увеличиться или уменьшиться в стоимости в любой момент времени,
                и это может привести к потере всего капитала, который вы
                инвестировали в транзакцию.
              </li>
              <li>
                Поэтому вы не должны торговать или инвестировать деньги, которые
                вы не можете позволить себе потерять. Важно, чтобы вы полностью
                понимали риски, связанные с торговлей на нашей платформе FItt,
                учитывая ваши финансовые ресурсы, уровень опыта и аппетит к
                риску. При необходимости вы должны обратиться за советом к
                независимому финансовому консультанту. Фактические доходы и
                потери, которые вы можете испытать, будут варьироваться в
                зависимости от многих факторов, включая, но не ограничиваясь
                поведением рынка, движением рынка и размером вашей сделки.
                Прошлое производительность не является гарантией будущей
                производительности. Стоимость ваших инвестиций может как
                увеличиваться, так и уменьшаться.
              </li>
              <li>
                При использовании нашей платформы FItt для торговли, есть риск
                того, что торговля может быть недоступна и/или вы можете
                потерять деньги в результате:
                <p className="mb-0">
                  (i) сбоя вашего компьютера, мобильного устройства или
                  цифрового устройства (включая его батарею);
                </p>
                <p className="mb-0">
                  (ii) слабого интернет- или мобильного соединения, включая
                  ошибки передачи, задержки, перерывы в сигналах сети, ошибки,
                  вызванные нестабильностью соединения устройства, что означает,
                  что вы не сможете подключиться к платформе, или если вы
                  сможете подключиться, может возникнуть задержка;
                </p>
                <p className="mb-0">
                  (iii) взлома или использования вредоносного программного
                  обеспечения, которое позволяет третьей стороне получить доступ
                  к вашей информации и/или активам;
                </p>
                <p className="mb-0">
                  (iv) Несовместимость вашего устройства с платформой или
                  системными требованиями, включая неправильные настройки или
                  системные требования;
                </p>
                <p className="mb-0">
                  (v) Любые происшествия, которые выходят за контроль платформы.
                </p>
              </li>
              <li>
                Несмотря на то, что FItt будет предпринимать разумные усилия,
                чтобы обеспечить стабильную и эффективную работу услуг, компания
                не может гарантировать, что не будет задержек в сети, сбоев в
                компьютерной системе, технических проблем, связанных с
                использованием компьютеров или систем передачи данных, на
                которых полагается компания и/или ее партнеры и поставщики,
                отказа оборудования, программного обеспечения или линий связи
                или других непредвиденных обстоятельств вынуждающих задерживать,
                приостанавливать, прерывать работу сервиса, производить
                неожиданные технические работы или выполнять услуги FItt не в
                соответствии с вашими ожиданиями. Вы признаете, что в случае
                любых таких сбоев (независимо от того, описаны они здесь или
                нет), компания не несет ответственности и не несет
                ответственности за любые возникшие убытки.
                <li>
                  Компания не несет ответственности за какие-либо претензии и
                  потери, возникающие в связи с любыми из вышеупомянутых
                  случаев, независимо от того, непосредственно ли они связаны с
                  компанией и/или ее партнерами и поставщиками.
                </li>
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Содержание FItt
            <ol>
              <li>
                При использовании FItt вы можете размещать, комментировать и/или
                представлять контент, который станет доступен через платформу
                FItt ("Ваш контент"). Вы полностью несете ответственность за Ваш
                контент, который вы размещаете, комментируете, представляете или
                иным образом делаете доступным через FItt. Следовательно,
                используя FItt и загружая Ваш контент или иным образом делая его
                доступным, вы предоставляете нам лицензию на доступ,
                использование, хранение, кэширование, копирование,
                воспроизведение, передачу, отображение, публикацию,
                распространение, адаптацию и изменение (для технических целей,
                например, чтобы убедиться, что контент можно просматривать на
                смартфонах, а также на компьютерах и других устройствах) Вашего
                контента в любых и всех средствах массовой информации или
                методах распространения (независимо от того, известны ли они на
                данный момент или будут разработаны в будущем), но исключительно
                для обеспечения функционирования и предоставления услуг FItt.
              </li>
              <li>
                Вы соглашаетесь с тем, что предоставляемая нами лицензия
                включает в себя право на предоставление, продвижение и улучшение
                FItt, а также на доступность Вашего контента для других
                компаний, организаций или физических лиц для распространения,
                продвижения или публикации Вашего контента на других медиа и
                сервисах. Вы соглашаетесь с тем, что эти права и лицензии
                являются неисключительными, безвозмездными, передаваемыми,
                сублицензируемыми, неотзывными и мировыми (на период, пока Ваш
                контент хранится у нас), и включают право для нас сделать Ваш
                контент доступным и передать эти права другим сторонам, с
                которыми у нас есть контрактные отношения, связанные с
                предоставлением FItt, и исключительно для целей предоставления
                FItt, а также допускать доступ к раскрытию Вашего контента
                третьим лицам, если мы определим, что такой доступ необходим для
                выполнения наших юридических обязательств. Вы полностью несете
                ответственность за безопасность своей учетной записи. Никогда не
                размещайте и/или не делитесь своими личными данными или
                информацией о безопасности, паролем и другой информацией,
                которая может поставить вашу учетную запись в опасность.
                Компания может просматривать и мониторить Ваш контент на
                платформе FItt. Несмотря на вышеизложенное, Компания может не
                соглашаться и/или не распространять условия Вашего контента.
              </li>
              <li>
                При размещении, комментировании и/или представлении Вашего
                контента на платформе FItt, Вы должны соблюдать Руководящие
                принципы контента. Вы должны убедиться, что Ваш контент
                принадлежит Вам или у Вас есть право размещать эту информацию, и
                что Вы не нарушаете авторские права, договорные права, права на
                публичность, права на конфиденциальность или любые другие права
                любого лица или организации.
              </li>
              <li>
                Вы несете ответственность за любой ущерб, возникший в результате
                нарушения авторских прав, товарных знаков или других прав
                собственности любого лица или организации, а также за любой
                другой вред или потери, возникшие в результате размещения Вашего
                контента. Вы признаете и соглашаетесь с тем, что Компания удалит
                и примет все необходимые правовые меры в отношении Вашего
                контента и вы: (i) не должны предоставлять инвестиционные
                советы, услуги управления портфелем или любые другие услуги;
                (ii) не должны публиковать, что Компания поддерживает или
                гарантирует Ваш контент; (iii) не должны рекламировать,
                указывать и/или продвигать другой бизнес или услугу, или любой
                тип коммерческого и/или рекламного контента, включая, но не
                ограничиваясь, спамом; и (iv) не должны размещать что-либо, что
                является незаконным, насильственным, расистским, оскорбительным,
                подстрекательным, угрожающим, клеветническим, досадным,
                сексуально откровенным, грубым или ненавистным.
              </li>
              <li>
                Компания может раскрыть Ваш контент, если это потребуется
                правоохранительным органам или государственным властям, чтобы
                помочь им в расследовании нарушения.
              </li>
              <li>
                Компания может публиковать информацию, такую как новости,
                графики, ссылки на другие веб-сайты, информационные бюллетени
                или информацию от третьих лиц и т.д. Нет гарантии, что такая
                информация точна и правильна, и в любом случае такая информация
                не должна рассматриваться как финансовый совет. Компания не
                несет ответственности и не имеет никакой ответственности за
                любые решения, которые Вы принимаете или не принимаете на основе
                такой информации. Вы несете все риски при использовании такой
                информации для принятия своих инвестиционных решений. 5.7 Вам
                запрещено и Вы не должны распространять информацию,
                опубликованную Компанией вне платформы FItt.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Отказ от гарантий <br />В полном объеме, допустимом в соответствии с
            применимым законодательством, услуги и продукты компании
            предоставляются «как есть» и «по наличию», и компания явно
            отказывается от любых других гарантий любого вида, явных или
            подразумеваемых, включая, но не ограничиваясь гарантиями товарной
            пригодности, соответствия определенной цели, права собственности или
            неприкосновенности прав или гарантий, вытекающих из
            производительности, деловых отношений или использования в торговле.
            Не ограничиваясь вышеуказанным, компания не представляет и не
            гарантирует, что платформа, услуги или продукты точны, полны,
            надежны, актуальны, свободны от ошибок или свободны от вирусов или
            других вредоносных компонентов. Компания не гарантирует, что
            какой-либо заказ будет исполнен, принят, записан или останется
            открытым. За исключением явных заявлений, соглашений и правил,
            изложенных в настоящих условиях FItt, вы признаете и соглашаетесь с
            тем, что вы не полагались на любое другое заявление или соглашение,
            явное или устное, относительно вашего использования и доступа к
            услугам компании. Не ограничиваясь вышеуказанным, вы понимаете и
            соглашаетесь с тем, что компания не несет ответственности за любые
            потери или убытки, возникающие в связи с: (А) любой неточностью,
            дефектом или упущением данных о цене цифровых активов; (Б) любой
            ошибкой или задержкой в передаче таких данных; (В) прерыванием в
            таких данных; (Г) регулярным или внеплановым техническим
            обслуживанием, проводимым компанией, прерываниями и изменениями в
            работе, возникающими в связи с таким техническим обслуживанием; (Д)
            любыми убытками, понесенными вследствие действий, бездействия или
            нарушения настоящих условий FItt другими пользователями;(F) ЛЮБОЙ
            ВРЕД, ПРИЧИНЕННЫЙ НЕЗАКОННЫМИ ДЕЙСТВИЯМИ ТРЕТЬИХ ЛИЦ ИЛИ ДЕЙСТВИЯМИ
            БЕЗ АВТОРИЗАЦИИ КОМПАНИИ; И (G) ДРУГИЕ ОТКАЗЫ, УКАЗАННЫЕ В ОТКАЗАХ И
            ПРАВИЛАХ ПЛАТФОРМЫ, ВЫДАННЫХ КОМПАНИЕЙ. ОТКАЗ ОТ ПОДРАЗУМЕВАЕМЫХ
            ГАРАНТИЙ, СОДЕРЖАЩИЙСЯ ЗДЕСЬ, МОЖЕТ НЕ ПРИМЕНЯТЬСЯ, ЕСЛИ ЭТО
            ЗАПРЕЩЕНО ДЕЙСТВУЮЩИМ ЗАКОНОМ ЮРИСДИКЦИИ, В КОТОРОЙ ВЫ ПРОЖИВАЕТЕ.
          </li>
          <li>
            Отказ от возмещения ущерба и ограничение ответственности
            <br /> В МАКСИМАЛЬНОЙ СТЕПЕНИ, ДОПУСТИМОЙ ДЕЙСТВУЮЩИМ ЗАКОНОМ, В
            НИКАКОМ СЛУЧАЕ КОМПАНИЯ, ЕЕ АФФИЛИРОВАННЫЕ ЛИЦА И ИХ СООБЩЕСТВА,
            ДИРЕКТОРЫ, СОТРУДНИКИ, АДВОКАТЫ, АГЕНТЫ, ПРЕДСТАВИТЕЛИ, ПОСТАВЩИКИ
            ИЛИ ПОДРЯДЧИКИ НЕ НЕСУТ ОТВЕТСТВЕННОСТИ ЗА ЛЮБОЙ СЛУЧАЙНЫЙ,
            КОСВЕННЫЙ, СПЕЦИАЛЬНЫЙ, НАКАЗУЕМЫЙ, ПОСЛЕДОВАТЕЛЬНЫЙ ИЛИ АНАЛОГИЧНЫЙ
            УЩЕРБ ИЛИ ОТВЕТСТВЕННОСТЬ ЛЮБОГО РОДА (ВКЛЮЧАЯ, НО НЕ ОГРАНИЧИВАЯСЬ
            ПОТЕРЕЙ ДАННЫХ, ИНФОРМАЦИИ, ДОХОДОВ, ПРИБЫЛЕЙ ИЛИ ДРУГИХ БИЗНЕС-ИЛИ
            ФИНАНСОВЫХ ПРЕИМУЩЕСТВ), ВОЗНИКАЮЩИХ В СВЯЗИ С УСЛУГАМИ КОМПАНИИ,
            ЛЮБОГО ВЫПОЛНЕНИЯ ИЛИ НЕВЫПОЛНЕНИЯ УСЛУГ КОМПАНИИ ИЛИ ЛЮБОГО ИНОГО
            ПРОДУКТА, УСЛУГИ ИЛИ ИНОГО ТОВАРА, ПРЕДОСТАВЛЯЕМОГО КОМПАНИЕЙ И ЕЕ
            АФФИЛИРОВАННЫМИ ЛИЦАМИ, НЕЗАВИСИМО ОТ ТОГО, НА ОСНОВЕ ДОГОВОРА,
            ЗАКОНА, КОНТРАКТА, СТРОГОЙ ОТВЕТСТВЕННОСТИ ИЛИ ДРУГОЙ ТЕОРИИ, ДАЖЕ
            ЕСЛИ КОМПАНИИ БЫЛО СКАЗАНО О ВОЗМОЖНОСТИ ТАКОГО УЩЕРБА, ЗА
            ИСКЛЮЧЕНИЕМ СЛУЧАЕВ, КОГДА ОТДЕЛЬНОЕ СУДЕБНОЕ РЕШЕНИЕ УСТАНОВИТ, ЧТО
            ТАКОЙ УЩЕРБ БЫЛ РЕЗУЛЬТАТОМ ГРУБОЙ НЕОСТОРОЖНОСТИ, МОШЕННИЧЕСТВА,
            УМЫШЛЕННОГО НАРУШЕНИЯ ИЛИ НАРУШЕНИЯ ЗАКОНА КОМПАНИЕЙ. НЕКОТОРЫЕ
            ЮРИСДИКЦИИ НЕ ДОПУСКАЮТ ИСКЛЮЧЕНИЯ ИЛИ ОГРАНИЧЕНИЯ ОТВЕТСТВЕННОСТИ
            ЗА СЛУЧАЙНЫЙ ИЛИ ПОСЛЕДОВАТЕЛЬНЫЙ УЩЕРБ, ТАК ЧТО ДАННОЕ ОГРАНИЧЕНИЕ
            МОЖЕТ НЕ СТОЯТЬ ДЛЯ ВАС.
            <br /> Иными словами, компания FITT, ее аффилированные лица и другие
            представители не несут ответственности за любой случайный,
            косвенный, специальный, наказуемый, последовательный или аналогичный
            ущерб или ответственность любого рода, возникающую в связи с
            услугами компании, иными товарами, услугами или продуктами,
            предоставляемыми компанией и ее аффилированными лицами, за
            исключением случаев, когда такой ущерб был вызван грубой
            неосторожностью, мошенничеством, умышленным нарушением или
            нарушением закона со стороны компании. Однако, в некоторых
            юрисдикциях ограничение ответственности за случайный или
            последовательный ущерб не допускается, поэтому данное ограничение
            может не применяться к вам. <br />
            Несмотря на вышесказанное, в никаком случае компания FITT, ее
            аффилированные лица, а также их акционеры, участники, директоры,
            сотрудники, юристы, агенты, представители, поставщики или подрядчики
            не несут ответственности за предоставляемые услуги от имени компании
            FITT и ее аффилированных лиц, любые выполняемые или невыполняемые
            услуги компании FITT или любые другие продукты, услуги или предметы,
            независимо от того, на основании каких договоров, законов, строгой
            ответственности или иных теорий это было сделано, в размере,
            превышающем сумму платежей, произведенных вами компании FITT в
            течение последних двенадцати месяцев, предшествующих событию,
            приведшему к убыткам. <br />
            Иными словами, ответственность компании FITT, ее аффилированных лиц
            и других представителей ограничена суммой, равной оплате, которую вы
            выплатили компании FITT за копирование торговых сделок в течение
            последних 12 месяцев, предшествующих событию, приведшему к убыткам,
            независимо от того, на основании каких договоров, законов, строгой
            ответственности или иных теорий это было сделано.
          </li>
          <li className="mt-3">
            Компенсация убытков
            <ol>
              <li>
                Вы соглашаетесь возместить и защищать компанию FITT, ее
                аффилированные лица, подрядчиков, партнеров, лицензиаров и их
                соответствующих директоров, должностных лиц, сотрудников и
                агентов от любых исков, действий, процессов, расследований,
                требований, исков, затрат, расходов и ущерба (включая гонорары
                адвокатов, штрафы или санкции, наложенные любым регулирующим
                органом), возникающих в связи с (i) вашим использованием или
                поведением в связи с услугами компании FITT; (ii) вашим
                нарушением или нашим соблюдением данных условий копирования
                торговых сделок; или (iii) вашим нарушением любого применимого
                закона, регуляции или права любой третьей стороны во время
                использования услуг компании FITT. Если вы обязаны возместить
                убытки компании FITT, ее аффилированных лиц, подрядчиков,
                лицензиаров, а также их соответствующих директоров, должностных
                лиц, сотрудников или агентов в соответствии с данными условиями,
                компания FITT имеет право, на свое усмотрение, контролировать
                любые действия или процессы и определить, хочет ли она
                прекратить спор и если да, то на каких условиях.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Объявления
            <ol>
              <li>
                Пожалуйста, будьте внимательны к официальным объявлениям и
                новостям, опубликованным на платформе. Пользователи обязуются
                регулярно и оперативно обращаться к этим материалам. Компания
                FITT не несет ответственности за какую-либо компенсацию, которую
                пользователи могут понести в связи с личными потерями,
                возникшими из-за незнания или небрежности при проверке
                содержания таких объявлений.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Соблюдение местных законов
            <ol>
              <li>
                Пользователи несут ответственность за соблюдение местных законов
                в отношении законного использования услуг компании FITT в своей
                юрисдикции, а также других законов и правил, применимых к
                пользователям. Пользователи также должны учитывать, в пределах
                своих местных законов, все аспекты налогообложения, удержания,
                сбора, отчетности и перевода в соответствующие налоговые органы.
                ВСЕ ПОЛЬЗОВАТЕЛИ УСЛУГ КОМПАНИИ ПОДТВЕРЖДАЮТ И ЗАЯВЛЯЮТ, ЧТО ИХ
                СРЕДСТВА ПРИХОДЯТ ИЗ ЛЕГИТИМНЫХ ИСТОЧНИКОВ И НЕ ПРОИСХОДЯТ ИЗ
                НЕЗАКОННЫХ ДЕЙСТВИЙ. ПОЛЬЗОВАТЕЛИ СОГЛАШАЮТСЯ, ЧТО КОМПАНИЯ
                БУДЕТ ТРЕБОВАТЬ ИЛИ ИНАЧЕ СОБИРАТЬ НЕОБХОДИМУЮ ИНФОРМАЦИЮ И
                МАТЕРИАЛЫ В СООТВЕТСТВИИ С РЕЛЕВАНТНЫМИ ЗАКОНАМИ И
                ПРАВИТЕЛЬСТВЕННЫМИ ПОСТАНОВЛЕНИЯМИ ДЛЯ ПРОВЕРКИ ЛЕГАЛЬНОСТИ
                ИСТОЧНИКОВ И ИСПОЛЬЗОВАНИЯ ИХ СРЕДСТВ. Компания FITT занимает
                позицию сотрудничества с правоохранительными органами по всему
                миру и не стесняется захватывать, замораживать, прекращать
                учетные записи и средства пользователей, которые были отмечены
                или расследуются в соответствии с законодательством.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Разрешение споров: форум, арбитраж, отказ от коллективного иска
            ПРОЧИТАЙТЕ ЭТУ СЕКЦИЮ ВНИМАТЕЛЬНО, ПОСКОЛЬКУ ОНА ПРЕДУСМАТРИВАЕТ
            ОТКАЗ ОТ НЕКОТОРЫХ ПРАВ НА ВЕДЕНИЕ СУДЕБНЫХ ПРОЦЕССОВ, ВКЛЮЧАЯ
            КОЛЛЕКТИВНЫЙ ИСК.
            <ol>
              <li>
                Уведомление о претензии и срок разрешения спора. Пожалуйста,
                сначала свяжитесь с Компанией! Компания хочет решить ваши
                проблемы без привлечения к формальным судебным процедурам, если
                это возможно. Если у вас есть спор с Компанией, то вы должны
                связаться с Компанией, и вам будет назначен номер заявки.
                Компания попытается разрешить ваш спор внутри компании как можно
                скорее. Стороны соглашаются вести переговоры добросовестно для
                разрешения спора (которые останутся конфиденциальными и будут
                подвергаться применяемым правилам, защищающим обсуждения мировых
                соглашений от использования в качестве доказательства в любом
                судебном процессе).
              </li>
              <li>
                В случае, если спор не может быть удовлетворительно решен, и вы
                хотите предъявить правовой иск к компании, вы соглашаетесь
                представить основания такого иска в письменной форме в виде
                "Уведомления о претензии", как формы предварительного
                уведомления компании. Уведомление о претензии должно: (i)
                описывать характер и основания претензии или спора; (ii)
                указывать конкретные требования к урегулированию; (iii)
                предоставлять исходный номер обращения; и (iv) содержать адрес
                электронной почты вашей учетной записи в компании. Уведомление о
                претензии должно быть отправлено на адрес электронной почты или
                гиперссылку, указанные в вашей переписке с компанией. После
                того, как вы предоставили Уведомление о претензии компании,
                спор, указанный в Уведомлении о претензии, может быть подан как
                компанией, так и вами на арбитраж в соответствии с пунктом 11.3
                ниже. Для избежания сомнений, подача спора в компанию для
                решения внутренним путем и предоставление Уведомления о
                претензии компании являются предварительными условиями для
                начала арбитражного разбирательства (или любого другого
                правового разбирательства). Во время арбитража размер любого
                предложения о досудебном урегулировании, сделанного вами или
                компанией, не будет сообщаться арбитру.
              </li>
              <li>
                Арбитраж. Если спор, возникающий из этого соглашения, не может
                быть решен путем переговоров, то спор должен быть разрешен путем
                арбитража, проводимого Американской арбитражной ассоциацией
                (AAA) в соответствии с ее Правилами коммерческого арбитража (за
                исключением любых правил или процедур, регулирующих или
                позволяющих коллективные иски). Проведение слушаний по арбитражу
                должно быть на английском языке в Нью-Йорке, Нью-Йорк, США.
                Арбитр
                <p>
                  11.3 Соглашение об арбитраже. Вы и операторы Компании
                  соглашаетесь, что за исключением пункта 11.1 выше, любой спор,
                  претензия или контроверзия между вами и Компанией (и/или
                  операторами Компании), возникшая в связи с Copy Trading
                  условиями или вашим отношением с Компанией (и/или операторами
                  Компании) как пользователем услуг Компании (независимо от
                  того, основаны ли претензии на контракте, деликте, статуте,
                  мошенничестве, заблуждении или другой юридической теории и
                  возникли ли претензии во время действия Copy Trading условий
                  или после их окончания), будут решаться в обязательном
                  окончательном и обязательном индивидуальном (не классовом)
                  арбитраже, за исключением того, что указано ниже в исключениях
                  из соглашения об арбитраже. Вы и операторы Компании также
                  соглашаетесь, что арбитр имеет исключительную власть решать
                  вопросы своей юрисдикции, включая, но не ограничиваясь
                  возражениями по поводу существования, объема или
                  действительности соглашения об арбитраже или возможности
                  арбитража какой-либо претензии или возражения. Арбитраж
                  является менее формальным, чем судебное разбирательство. В
                  арбитраже нет судьи или жюри, а обзор судом решения арбитра
                  ограничен. Может быть ограниченный объем доказательств, чем в
                  суде. Арбитр должен следовать этому соглашению и может
                  присуждать те же ущерб и возмещение, что и суд (в том числе,
                  если применимо, адвокатские гонорары), за исключением того,
                  что арбитр не может присуждать декларативное или
                  запретительное облегчение в пользу кого-либо, кроме сторон
                  арбитража. Положения об арбитраже, изложенные в данном
                  разделе, сохраняют силу после прекращения Copy Trading
                  условий.
                </p>
              </li>
              <li>
                Правила арбитража. Арбитраж будет проводиться в соответствии с
                правилами администрирования арбитража HKIAC (HKIAC Rules),
                действующими на момент подачи уведомления об арбитраже, с
                изменениями, предусмотренными настоящим разделом. Арбитраж будет
                проводиться Гонконгским международным арбитражным центром
                (HKIAC). Если стороны не договорятся обратного, назначится
                только один арбитр в соответствии с правилами HKIAC. Любой
                арбитраж будет проводиться на английском языке. Независимо от
                того, как будет проводиться арбитраж, арбитр должен вынести
                обоснованное письменное решение, достаточное для объяснения
                основных выводов и заключений, на которых основывается решение и
                приговор, если таковые будут вынесены. Все упоминания о Copy
                Trading заменены на FItt. РЕШЕНИЕ О ЛЮБОМ АРБИТРАЖНОМ РЕШЕНИИ
                МОЖЕТ БЫТЬ ПРОВЕДЕНО В ЛЮБОМ СУДЕ, КОТОРЫЙ ИМЕЕТ ЮРИСДИКЦИЮ НАД
                СТОРОНОЙ (ИЛИ НАД АКТИВАМИ СТОРОНЫ), ПРОТИВ КОТОРОЙ ТАКОЕ
                РЕШЕНИЕ ПРИНЯТО. СРОК ДЛЯ ПОДАЧИ ЗАЯВЛЕНИЯ ОБ АРБИТРАЖЕ: ЛЮБОЙ
                АРБИТРАЖ ПРОТИВ ОПЕРАТОРОВ КОМПАНИИ ДОЛЖЕН БЫТЬ НАЧАТ ПОДАЧЕЙ
                ЗАПРОСА ОБ АРБИТРАЖЕ В ТЕЧЕНИЕ ОДНОГО (1) ГОДА ПОСЛЕ ДАТЫ, КОГДА
                СТОРОНА, ВЫДВИГАЮЩАЯ ПРЕТЕНЗИЮ, ВПЕРВЫЕ ЗНАЕТ ИЛИ РАЗУМНО ДОЛЖНА
                ЗНАТЬ О ДЕЙСТВИЯХ, УПУЩЕНИЯХ ИЛИ НЕСООТВЕТСТВИЯХ, КОТОРЫЕ
                ПРИВЕЛИ К ТАКОЙ ПРЕТЕНЗИИ; И НЕ БУДЕТ ПРАВА НА ЛЮБОЕ ВОЗМЕЩЕНИЕ
                ПО ЛЮБОЙ ПРЕТЕНЗИИ, КОТОРАЯ НЕ БЫЛА ВЫДВИНУТА В ТЕЧЕНИЕ ЭТОГО
                СРОКА. ЭТОТ ОГРАНИЧИТЕЛЬНЫЙ СРОК ОДНОГО ГОДА ВКЛЮЧАЕТ В СЕБЯ
                ВНУТРЕННИЕ ПРОЦЕДУРЫ РАЗРЕШЕНИЯ СПОРОВ, УСТАНОВЛЕННЫЕ В
                ПАРАГРАФЕ 1 ЭТОГО РАЗДЕЛА, ВЫШЕ. НЕ БУДЕТ ПРАВА НА ЛЮБОЕ
                ВОЗМЕЩЕНИЕ ПО ЛЮБОЙ ПРЕТЕНЗИИ, КОТОРАЯ НЕ БЫЛА ВТЕЧЕНИЕ ЭТОГО
                СРОКА. <br />
                Решение о любом арбитражном решении может быть выполнено в любом
                суде, который имеет юрисдикцию над стороной (или над активами
                стороны), против которой такое решение принято. Срок для подачи
                заявления об арбитраже: любой арбитраж против операторов
                компании должен быть начат подачей запроса об арбитраже в
                течение одного (1) года после даты, когда сторона, выдвигающая
                претензию, впервые знает или разумно должна знать о действиях,
                упущениях или несоответствиях, которые привели к такой
                претензии; и не будет права на любое возмещение по любой
                претензии, которая не была выдвинута в течение этого срока. Этот
                ограничительный срок одного года включает в себя внутренние
                процедуры разрешения споров, установленные в параграфе 1 этого
                раздела, выше. Не будет права на любое возмещение по любой
                претензии, которая не была выдвинута в течение этого срока.
                <br />
                Если применимое законодательство запрещает срок давности в один
                год для предъявления претензий, любая претензия должна быть
                предъявлена в кратчайший срок, допустимый применимым
                законодательством. Процесс; Уведомление: Сторона, которая
                намеревается обратиться к арбитражу после истечения периода
                разрешения споров, установленного в параграфе 1 выше, должна
                подать запрос в HKIAC в соответствии с правилами HKIAC. Если мы
                обратимся к арбитражу против вас, мы предоставим вам уведомление
                на электронный адрес или почтовый адрес, который вы
                предоставили. Вы соглашаетесь с тем, что любое уведомление,
                отправленное на этот электронный адрес или почтовый адрес,
                считается эффективным для всех целей, включая, но не
                ограничиваясь, определение достаточности службы. Вашей
                обязанностью является обеспечение того, чтобы электронный адрес
                и/или почтовый адрес, указанные в данных компании, были
                актуальными и точными. Место арбитража: Местом арбитража
                является Гонконг. Место проведения слушаний: Местом проведения
                любых личных слушаний в рамках арбитража является Гонконг, если
                стороны не договорятся обратного.
              </li>
              <li>
                Применимое право. Эти условия FItt (включая это соглашение об
                арбитраже) будут регулироваться и толковаться в соответствии с
                законодательством Гонконга. Конфиденциальность. Стороны
                соглашаются, что арбитраж должен быть сохранен конфиденциальным.
                Существование арбитража, любая не общедоступная информация,
                предоставленная в арбитраже, и любые заявления, решения или
                награды, принятые в арбитраже (вместе "Конфиденциальная
                информация"), не должны раскрываться ни одной сторонней стороне,
                за исключением третейского суда, HKIAC, сторон, их адвокатов,
                экспертов, свидетелей, бухгалтеров и аудиторов, страховщиков и
                перестраховщиков и любых других лиц, необходимых для проведения
                арбитража. Несмотря на вышеизложенное, сторона может раскрывать
                конфиденциальную информацию в той мере, в которой раскрытие
                может быть необходимо для выполнения юридического обязательства,
                защиты или осуществления юридического права или обжалования
                решения в добросовестных юридических процессах. Это положение о
                конфиденциальности сохраняется после прекращения данных условий
                и любого арбитража, рассмотренного в соответствии с данными
                условиями.
              </li>
              <li>
                Отказ от коллективных исков. Вы и Компания соглашаетесь на то,
                что любые претензии, относящиеся к этим условиям FItt или к
                вашим отношениям с Компанией в качестве пользователя услуг
                Компании (независимо от того, основаны ли претензии на договоре,
                деликте, законе, мошенничестве, заблуждении или любой другой
                правовой теории и возникнут ли претензии во время или после
                прекращения данных условий FItt) должны быть предъявлены против
                другой стороны в рамках индивидуального арбитража и не в
                качестве истца или участника группового или представительного
                иска. Вы и Компания также соглашаетесь отказаться от права на
                предъявление таких претензий в рамках коллективного,
                совместного, представительного или действия частного
                генерального адвоката, в той мере, в которой это допустимо в
                соответствии с применимым законодательством. Совмещение или
                объединение индивидуальных арбитражей в рамках одного арбитража
                не допускается без согласия всех сторон, включая Компанию.
              </li>
              <li>
                Изменения. Компания оставляет за собой право обновлять,
                изменять, пересматривать, приостанавливать или вносить любые
                будущие изменения в настоящий раздел, касающийся соглашения
                сторон об арбитраже, в соответствии с применимым
                законодательством. Вы даете свое согласие и соглашаетесь с тем,
                что вы несете ответственность за обновление своего понимания
                настоящего раздела. В соответствии с применимым
                законодательством, продолжение использования вашей учетной
                записи в Компании будет считаться вашим согласием с любыми
                изменениями в настоящем разделе, касающемся соглашения сторон об
                арбитраже. Вы соглашаетесь с тем, что если вы возражаете против
                изменений в данном разделе, то Компания может заблокировать
                доступ к вашей учетной записи до ее закрытия. В таких
                обстоятельствах данные условия FItt, действующие до внесения
                изменений, останутся в полной силе до закрытия вашей учетной
                записи.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Разное
            <ol>
              <li>
                Независимые стороны. Компания является независимым подрядчиком,
                но не агентом для вас в выполнении данных условий FItt. Данные
                условия FItt не должны рассматриваться как факты или
                доказательства ассоциации, совместного предприятия, партнерства
                или франшизы между сторонами.
              </li>
              <li>
                Полное соглашение. Данные условия FItt составляют полное
                соглашение между сторонами относительно использования услуг
                Компании и заменят все предыдущие письменные или устные
                соглашения между сторонами. Никакое торговое использование или
                другая общепринятая практика или способ взаимодействия между
                сторонами не будет использоваться для изменения, толкования,
                дополнения или изменения условий здесь.
              </li>
              <li>
                Толкование и изменение. Компания оставляет за собой право в
                любое время изменять, пересматривать, модифицировать и/или
                изменять данные условия FItt. Все изменения вступят в силу
                немедленно после их публикации на платформе Компании. Вашей
                обязанностью является регулярно проверять соответствующие
                страницы на наших веб-сайтах/приложениях, чтобы подтвердить
                последнюю версию данных условий FItt. Если вы не согласны с
                какими-либо такими изменениями, единственным вашим способом
                устранения является прекращение использования услуг Компании и
                отмена вашей учетной записи. Вы соглашаетесь с тем, что, если не
                оговорено иное в настоящих условиях FItt, Компания не несет
                ответственности за любые изменения или прекращение услуг
                Компании с вашей стороны или со стороны третьих лиц, или за
                приостановку или прекращение доступа к услугам Компании.
              </li>
              <li>
                Форс-мажор. Компания не будет нести ответственность за любую
                задержку или невыполнение в соответствии с данными условиями
                FItt из-за любой причины или условия, выходящих за разумный
                контроль Компании.
              </li>
              <li>
                Разделимость. Если какая-либо часть данных условий FItt будет
                признана недействительной или невыполнимой, такая
                недействительность или невыполнимость не повлияет на другие
                положения данных условий FItt, которые останутся в полной силе и
                эффекте, а недействительная или невыполнимая часть будет
                реализована в максимально возможной степени.
              </li>
              <li>
                Передача прав. Вы не можете передавать или переводить какое-либо
                право на использование услуг Компании или любые из ваших прав
                или обязательств по данным условиям FItt без предварительного
                письменного согласия от Компании, включая любое право или
                обязательство, связанное с применением законов или сменой
                контроля. Компания может передавать или переводить любые свои
                права или обязательства по данным условиям FItt, полностью или
                частично, без уведомления или получения вашего согласия или
                одобрения.
              </li>
              <li>
                Отказ от права. Отказ одной стороны от требования исполнения
                какого-либо условия не повлияет на ее право потребовать
                исполнения в любое время впоследствии. Одновременно отказ одной
                стороны от иска по нарушению этих условий сотрудничества или
                любого положения применимых условий не означает отказ этой
                стороны от любого последующего нарушения или нарушения со
                стороны другой стороны или самого условия.
              </li>
              <li>
                Отказ от ответственности за сайты третьих сторон. Любые ссылки
                на сайты третьих сторон, предоставленные Компанией, не означают,
                что Компания одобряет какой-либо продукт, услугу, информацию или
                отказ от ответственности, представленные на этих сайтах, а также
                Компания не гарантирует точность информации, содержащейся на
                них. Если вы понесете убытки от использования таких продуктов и
                услуг третьих сторон, Компания не будет нести ответственности за
                такие убытки. Кроме того, поскольку Компания не имеет контроля
                над условиями использования или политикой конфиденциальности
                сайтов третьих сторон, вы должны внимательно прочитать и понять
                эти политики.
              </li>
            </ol>
          </li>
          <li className="mt-3">
            Контакты
            <ol>
              <li>
                Общие вопросы или комментарии по FItt Trading или условиям
                использования FItt Trading должны быть отправлены по адресу
                электронной почты support@fitt.ink.
              </li>
            </ol>
          </li>
        </ol>
      </article>
    </>
  );
}
