import React from "react";
import "./SignalGuide.css";

export default function SignalGuide() {
  return (
    <div className="SignalGuide question-routes-container">
      <h1>Функция "Сигналы"</h1>
      <p>
        Функция "Сигналы" - это инструмент на нашем сайте по копитрейдингу,
        который позволяет пользователям следить за активностью выбранных ими
        трейдеров. Когда выбранный трейдер совершает сделку, информация об этой
        сделке автоматически отправляется пользователю через нашего бота в
        Telegram.
      </p>
      <dl>
        <dt>Как это работает</dt>
        <dd>
          Пользователь может выбрать любого трейдера из списка на нашем сайте
          для подписки на его сигналы. После того как пользователь подпишется,
          наш бот в Telegram начнет отправлять ему уведомления о каждой сделке,
          совершенной выбранным трейдером. Эти уведомления включают детали
          сделки, такие как тип сделки (покупка или продажа), объем, цену, и
          дату и время сделки.
        </dd>
        <dt>Зачем это нужно </dt>
        <dd>
          Функция "Сигналы" создана для того, чтобы помочь пользователям делать
          более обоснованные решения о торговле. Подписавшись на сигналы от
          трейдера, который имеет стабильную историю успешных сделок,
          пользователи могут получать вдохновение для собственной торговли или
          повторять сделки этого трейдера, следуя принципам копитрейдинга.
        </dd>
      </dl>
      <h4 className="mb-3 signal-guide-header">Преимущества</h4>
      <dl>
        <dt>1. Обучение:</dt>
        <dd>
          Новички в торговле могут использовать функцию "Сигналы", чтобы учиться
          у опытных трейдеров, наблюдая за их сделками.
        </dd>
        <dt>2. Информированность:</dt>
        <dd>
          Пользователи всегда будут в курсе активности выбранного трейдера и
          смогут быстро реагировать на изменения на рынке.
        </dd>
        <dt>3. Удобство:</dt>
        <dd>
          Уведомления о сделках приходят прямо в Telegram, что позволяет
          пользователям узнавать о сделках в режиме реального времени, где бы
          они ни находились.
        </dd>
        <dt>4. Гибкость:</dt>
        <dd>
          Пользователи купившие «эксперт» тариф, могут подписаться на сигналы от
          любого количества трейдеров, управлять своими подписками в любое время
          и выбирать, какую информацию они хотят получать.
        </dd>
      </dl>
      <h6 className="signal-guide-header">Важно знать</h6>
      <p>
        Функция "Сигналы" предоставляет информацию о действиях других трейдеров,
        но не является прямым призывом к действию. Ответственность за принятие
        решений о торговле лежит плотностью на Вас.
      </p>
    </div>
  );
}
