import React from "react";

export default function Answer2() {
    return (
        <>
            Принцип социального трейдинга прост: Копируйте фьючерсные сделки
            опытных и успешных инвесторов (лидеров-трейдеров) в реальном
            времени, которым вы желаете следовать. Таким образом, каждый раз,
            когда они торгуют, вы будете автоматически повторять (копировать) их
            сделки на своем аккаунте FItt. Социальный трейдинг FItt соединяет
            часть вашего портфеля с портфелем выбранного вами трейдера. Когда вы
            копируете трейдера, все его открытые сделки копируются на ваш
            аккаунт.
        </>
    );
}
