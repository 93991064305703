import React from "react";

export default function Referral() {
  return (
    <div className="Referral question-routes-container">
      <h1>Реферальная программа</h1>
      <dl>
        <dt>1. Регистрация в программе:</dt>
        <dd>
          Пользователи должны зарегистрироваться или войти в систему, чтобы
          получить уникальную ссылку для рефералов. Эта ссылка используется для
          отслеживания всех приглашенных пользователей.
        </dd>
        <dt>2. Приглашение друзей:</dt>
        <dd>
          Пользователи могут приглашать своих друзей и знакомых на сайт с
          помощью своей уникальной реферальной ссылки. Они могут делиться
          ссылкой через различные каналы, такие как социальные сети, электронная
          почта, и т.д.
        </dd>
        <dt>3. Активация подписки:</dt>
        <dd>
          Когда приглашенный друг активирует подписку на сайте, пользователь,
          пригласивший его, получает вознаграждение. В этом случае,
          вознаграждение составляет 20% от стоимости подписки.
        </dd>
        <dt>4. Получение комиссии:</dt>
        <dd>
          Вознаграждение начисляется на баланс пользователя в виде криптовалюты.
          Он может использовать эти средства для торговли на сайте или для
          вывода на свой собственный кошелек.
        </dd>
      </dl>
    </div>
  );
}
