import React from "react";
import "./FAQ.css";

export default function FAQ() {
  return (
    <div className="FAQ question-routes-container">
      <h1>FAQ по использованию FITT</h1>
      <dl>
        <dt>1. Регистрация на сайте Fitt</dt>
        <dd>Для начала вам нужно пройти регистрацию на сайте Fitt.</dd>
        <dt>2. Регистрация и верификация на Binance</dt>
        <dd>
          Чтобы пользоваться нашим сервисом, вам необходимо пройти регистрацию и
          верификацию на площадке Binance.
        </dd>
        <dt>3. Создание ключа API для торговли фьючерсами</dt>
        <dd>
          Если у вас уже есть аккаунт на Binance, создайте ключ API для торговли
          фьючерсами. Для этого перейдите в раздел API на Binance:
          https://www.binance.com/ru-UA/my/settings/api-management и создайте
          ключ. Добавьте IP-адрес сайта (13.229.50.26) в ключ API для
          возможности совершения сделок. При генерации ключа выберите
          "Управление фьючерсным аккаунтом" и не выбирайте доступ к снятию
          денежных средств. Сохраните на надежный носитель API Key и Secret Key
          (Secret Key показывается один раз).
        </dd>
        <dt>4. Ввод ключа API и Secret Key</dt>
        <dd>
          Введите ваш API Key и Secret Key в раздел "Управление API" на сайте
          Fitt.
        </dd>
        <dt>5. Выбор лидера и настройка бота</dt>
        <dd>
          На главной странице Fitt выберите лидера, за которым хотите следовать,
          и нажмите "Следить". Затем появятся настройки бота, включая:
          <ol type="I" className="mt-2">
            <li>
              <dt className="inner-descrip-term">
                Выбор режима (1 из 3 опций):
              </dt>
              <dd>
                На главной странице Fitt выберите лидера, за которым хотите
                следовать, и нажмите "Следить". Затем появятся настройки бота,
                включая:
              </dd>
            </li>
            <li>
              <dt className="inner-descrip-term">Выбор суммы инвестиции:</dt>
              <dd>Установите ваш бюджет для инвестиций.</dd>
            </li>
            <li>
              <dt className="inner-descrip-term">Выбор размера риска:</dt>
              <dd>Определите уровень риска, на который вы готовы пойти.</dd>
            </li>
            <li>
              <dt className="inner-descrip-term">
                Контроль сделки (опционально):
              </dt>
              <dd>
                Можете выбрать процент от баланса, на который возможен вход в
                сделку. После настройки бота нажмите "Вперед" для подтверждения.
              </dd>
            </li>
          </ol>
        </dd>
        <dt>6. Настройки копирования (1 из 3 опций):</dt>
        <dd>
          <ul className="inner-descrip-list">
            <li>Копировать только новые позиции</li>
            <li>Копировать позиции с отрицательным PNL-ROI</li>
            <li>Копировать все открытые позиции</li>
          </ul>
        </dd>
        <dt>7. Просмотр открытых позиций лидера</dt>
        <dd>
          На странице настройки копирования можно увидеть открытые позиции
          выбранного лидера.
        </dd>
        <dt>8. Сохранение и запуск бота</dt>
        <dd>Нажмите "Сохранить" и затем "Запустить" для активации бота.</dd>
        <dt>9. Мониторинг статистики бота</dt>
        <dd>
          После запуска бота вы можете отслеживать его статистику в разделе "Мои
          Боты" на кнопке "Статистика".
        </dd>
        <dt>10. Управление ботом</dt>
        <dd>
          В разделе "Мои Боты" вы можете управлять вашим ботом: остановить бота,
          запустить бота, редактировать настройки бота, удалить бота или
          просмотреть статистику бота.
        </dd>
        <dt>11. Просмотр сделок и управление ими</dt>
        <dd>
          Все сделки, созданные вашими ботами, а также ваши личные сделки,
          находятся в разделе "Торговля". В этом разделе вы можете узнать
          подробную статистику по сделкам, а также, при необходимости, закрыть
          сделку по рынку.
        </dd>
      </dl>
      <p>
        Теперь у вас есть полное представление о функциях и возможностях нашего
        сервиса копитрейд торговли на криптовалютном рынке. Воспользуйтесь ими
        для успешной торговли и роста вашего капитала!
      </p>
    </div>
  );
}
