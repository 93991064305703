import React from "react";

export default function FITT() {
  return (
    <div className="FITT question-routes-container">
      <h1>Что такое FITT</h1>
      <div>
        <b>FITT</b> – это платформа, сокращающая дистанцию между опытными и
        начинающими трейдерами, позволяя находить, отслеживать и копировать
        сделки умелых трейдеров по всему миру. FItt не просто предоставляет
        возможность копирования сделок, но также создает сообщество трейдеров и
        инвесторов, где вы можете общаться и взаимодействовать с
        единомышленниками в области трейдинга. Также вы сможете расширить свой
        круг взаимодействия, общаясь с лучшими трейдерами из разных стран,
        обсуждая стратегии и методы, которые помогут вам стать успешным
        трейдером.
      </div>
    </div>
  );
}
