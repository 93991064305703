import React from "react";

export default function Confidentiality() {
  return (
    <div className="Confidentiality question-routes-container">
      <h1>FITT Privacy Statement</h1>
      <p>
        Обратите внимание, что данное заявление о конфиденциальности будет
        регулярно обновляться, чтобы отражать любые изменения в способе
        обработки ваших персональных данных или любые изменения в применимых
        законах. Это заявление о конфиденциальности описывает, как FITT собирает
        и обрабатывает ваши персональные данные через веб-сайты и мобильные
        приложения FITT или другие связанные сервисы или приложения, которые
        ссылаются на это заявление о конфиденциальности, а также какие у вас
        есть права в отношении обработки ваших персональных данных. Используя
        услуги, предоставляемые FITT («услуги FITT»), вы соглашаетесь с сбором,
        хранением, обработкой и передачей ваших персональных данных, описанных в
        данном заявлении о конфиденциальности. Компания FITT, чей адрес
        находится в Žalgirio g. 90, 09300 Vilnius, Литва, является
        контролирующим лицом за персональную информацию, собираемую и
        обрабатываемую в связи с предоставлением услуг FITT.
      </p>
      <dl>
        <dt>
          1. Какие персональные данные пользователей собирает FITT? Для каких
          целей обрабатывает персональные данные пользователей FITT? Для каких
          целей и на каких правовых основаниях мы используем ваши персональные
          данные?
        </dt>
        <dd>
          FITT собирает персональные данные наших сотрудников, потенциальных
          сотрудников, клиентов, поставщиков, деловых контактов, акционеров и
          пользователей веб-сайтов. Если данные, которые мы собираем, не указаны
          в этом заявлении о конфиденциальности, мы предоставим лицам (если это
          требуется законом) соответствующее уведомление о том, какие другие
          данные будут собираться и как они будут использоваться. Ниже также
          приведена таблица, описывающая категории персональных данных, которые
          мы собираем.
        </dd>
        <dt>Какую информацию и личные данные обрабатывает FITT?</dt>
        <dd>
          адрес электронной почты;
          <br />
          имя; <br />
          пол; <br />
          дата рождения; <br />
          домашний адрес; <br />
          номер телефона; <br />
          гражданство; <br />
          ID устройства; <br />
          Интернет-протокол (IP) адрес, используемый для подключения вашего
          компьютера к Интернету; <br />
          Логин, адрес электронной почты, пароль и местоположение вашего
          устройства или компьютера; <br />
          Метрики услуг FITT (например, случаи технических ошибок,
          взаимодействие с функциями и контентом сервиса, предпочтения в
          настройках); <br />
          Версия и часовой пояс; <br />
          История транзакций;
          <br />
          Информация из других источников: мы можем получать информацию о вас из
          других источников, таких как информация о кредитной истории от
          кредитных бюро; <br />
          Информация о вашем поведении: мы можем обрабатывать информацию о вас,
          касающуюся вашего поведения и активности в целях маркетинга и рекламы.
          <br />
          Интернет-протокол (IP) адрес, используемый для подключения вашего
          компьютера и приложения к Интернету. <br />
          Логин, адрес электронной почты, пароль и местоположение вашего
          устройства или компьютера. <br />
          Метрики услуг FITT (например, случаи технических ошибок,
          взаимодействие с функциями и контентом сервиса, предпочтения в
          настройках); <br />
          Версия и часовой пояс. <br />
          История транзакций.
        </dd>
        <dt>Зачем FITT обрабатывает мою личную информацию?</dt>
        <dd>
          Услуги транзакций. Мы используем вашу личную информацию для обработки
          заказов и транзакций, а также для связи с вами о заказах и услугах;
          <br />
          Связь с вами. Мы используем вашу личную информацию для общения с вами
          в связи с услугами FITT; <br />
          Предоставление, устранение неполадок и улучшение услуг FITT. Мы
          используем вашу личную информацию для обеспечения функциональности,
          анализа производительности, исправления ошибок и повышения удобства и
          эффективности услуг FITT. <br />
          Предотвращение мошенничества и кредитных рисков. Мы обрабатываем
          личную информацию для предотвращения и выявления мошенничества и
          злоупотреблений с целью защиты безопасности наших пользователей, услуг
          FITT и других. Мы также можем использовать методы скоринга для оценки
          и управления кредитными рисками.Улучшение наших услуг. Мы обрабатываем
          личную информацию для улучшения наших услуг и для предоставления вам
          лучшего пользовательского опыта; <br />
          Рекомендации и персонализация. Мы используем вашу личную информацию
          для рекомендации функций и услуг, которые могут быть вам интересны,
          определения ваших предпочтений и персонализации вашего опыта с
          услугами FITT; <br />
          Автоматически собираемая информация. Мы автоматически собираем и
          храним определенные типы информации о вашем использовании услуг FITT,
          включая ваше взаимодействие с контентом и услугами, доступными через
          FITT. Как и многие другие сайты, мы используем файлы cookie и другие
          уникальные идентификаторы, и мы получаем определенные типы информации,
          когда ваш веб-браузер или устройство получает доступ к услугам FITT.
        </dd>
        <dt>Основания для использования личной информации.</dt>
        <dd>
          <b>
            Исполнение контракта, когда мы предоставляем вам товары или услуги
            или связываемся с вами по этому поводу. Это включает использование
            вашей личной информации для приема и обработки заказов и проведения
            транзакций. Наши законные интересы и интересы наших пользователей,
            когда, например, мы выявляем и предотвращаем мошенничество и
            злоупотребления для защиты безопасности наших пользователей, нас
            самих или других; Наши законные интересы и интересы наших
            пользователей, когда, например, мы выявляем и предотвращаем
            мошенничество и злоупотребления для защиты безопасности наших
            пользователей, нас самих или других.Наш законный интерес в улучшении
            наших услуг; Ваше согласие, когда мы просим ваше разрешение на
            обработку вашей личной информации для конкретной цели, которую мы
            сообщаем вам. Когда вы даете согласие на обработку вашей личной
            информации для указанной цели, вы можете в любое время отозвать свое
            согласие, и мы прекратим обработку данных для этой цели. Ваше
            согласие, когда мы просим ваше разрешение на обработку вашей личной
            информации для конкретной цели, которую мы сообщаем вам. Когда вы
            даете согласие на обработку вашей личной информации для указанной
            цели, вы можете в любое время отозвать свое согласие, и мы прекратим
            обработку данных для этой цели.
          </b>
          <br />
          За исключением определенной информации, которая требуется законом или
          политиками, ваше решение предоставить нам любые персональные данные
          является добровольным. Поэтому вы не будете подвергаться
          неблагоприятным последствиям, если не желаете предоставить нам свои
          персональные данные. Однако, обратите внимание, что если вы не
          предоставите определенную информацию, мы можем не смочь выполнить
          некоторые или все цели, изложенные в данном заявлении о
          конфиденциальности, и вы не сможете использовать определенные
          инструменты и системы, которые требуют использования таких
          персональных данных.
          <br /> Указанные выше категории персональных данных были получены либо
          напрямую от вас (например, когда вы предоставляете информацию для
          подписки на новостную рассылку или регистрации на веб-сайте), либо
          косвенно от определенных третьих сторон (например, через технологию
          нашего веб-сайта). Такие третьи стороны включают наших аффилированных
          лиц, государственные органы, общественные веб-сайты и социальные сети,
          поставщиков и продавцов.
          <br /> В тех случаях, когда вы даете свое согласие на обработку ваших
          персональных данных в соответствии с таблицей выше, такое согласие
          будет рассматриваться как обязательное в соответствии с применимыми
          законами. <br />В той степени, в которой вам будет предложено нажать
          на кнопку/чекбокс/функциональность «Я согласен», «Я соглашаюсь» или
          аналогичные в отношении заявления о конфиденциальности, это будет
          считаться предоставлением вашего согласия на обработку ваших
          персональных данных только в тех странах, где такое согласие требуется
          по обязательному закону. Во всех остальных странах такое действие
          будет рассматриваться как простое подтверждение, и юридическим
          основанием обработки ваших персональных данных не будет ваше согласие,
          а любое другое применимое юридическое основание. Мы не будем
          использовать ваши персональные данные для целей, несовместимых с
          целями, о которых вы были информированы, если только это не требуется
          или не авторизовано законом или это не является в вашем собственном
          жизненном интересе. <br />
          Переработка: <br />
          За исключением информации, которая требуется законом или политикой,
          решение предоставить личные данные нами является добровольным. Если вы
          не хотите предоставлять свои личные данные, вас не будут подвергать
          негативным последствиям. Однако, пожалуйста, обратите внимание, что
          если вы не предоставите определенную информацию, мы можем не сможем
          достигнуть всех целей, описанных в нашей политике конфиденциальности,
          и вы можете не иметь возможности использовать некоторые инструменты и
          системы, требующие таких личных данных. <br />
          Категории личных данных, о которых упоминается выше, были получены
          напрямую от вас (например, при подписке на новостную рассылку или
          регистрации на сайте), либо косвенно от третьих сторон (например,
          через технологию нашего сайта), включая наших аффилированных лиц,
          государственные органы, общественные сайты и социальные сети,
          поставщиков и продавцов.
          <br /> В тех случаях, когда вам предлагается дать согласие на
          обработку ваших личных данных, в соответствии с вышеуказанной
          таблицей, мы будем считать, что такое согласие является обязательным в
          соответствии с применимыми законами.
          <br /> Если вам будет предложено нажать кнопку «Я принимаю», «Я
          согласен» или аналогичную, в отношении политики конфиденциальности, в
          тех странах, где такое согласие требуется по обязательному закону, это
          будет считаться предоставлением вашего согласия на обработку личных
          данных. Во всех остальных случаях это будет считаться простым
          подтверждением, и юридическим основанием обработки личных данных будет
          другое применимое законодательство.
          <br /> Мы не будем использовать ваши личные данные для целей,
          несовместимых с целями, о которых мы вас уведомили, если это не
          требуется законом или не является в вашем жизненном интересе.
        </dd>
        <dt>2. Могут ли дети использовать услуги FITT?</dt>
        <dd>
          FITT не разрешает использовать свои услуги лицам младше восемнадцати
          (18) лет.
        </dd>
        <dt>3. Как насчет куки-файлов и других идентификаторов?</dt>
        <dd>
          Мы используем куки-файлы и подобные инструменты для улучшения
          пользовательского опыта, предоставления наших услуг и понимания того,
          как клиенты используют наши услуги, чтобы вносить улучшения. Чтобы
          наши системы могли распознать ваш браузер или устройство и
          предоставлять вам услуги FITT, мы используем куки-файлы. Мы используем
          операционные куки-файлы и аналогичные инструменты (вместе именуемые
          "куки-файлы") для предоставления наших услуг, например: Распознавание
          вас при входе в наши услуги. Предоставление индивидуальных функций и
          услуг. Отображение функций и услуг, которые могут быть вам интересны,
          включая рекламу на наших услугах. Предотвращение мошеннической
          деятельности. Повышение безопасности. Отслеживание ваших предпочтений,
          таких как валюта и язык. Мы также используем куки-файлы для изучения
          того, как пользователи используют наши услуги, чтобы вносить
          улучшения. Настройки вашего браузера сообщат вам, как запретить
          браузеру принимать новые куки-файлы, как заставить браузер уведомлять
          вас о получении нового куки-файла, как отключить и удалить куки-файлы
          и когда куки-файлы истекут. Операционные куки-файлы позволяют вам
          воспользоваться некоторыми основными функциями FITT. Если вы
          заблокируете или иным образом отклоните операционные куки-файлы через
          настройки вашего браузера, некоторые функции и услуги могут не
          работать.
        </dd>
        <dt>
          4. Делится ли FITT моей личной информацией с третьими сторонами?
        </dt>
        <dd>
          Информация о наших пользователях является важной частью нашего
          бизнеса, и мы не занимаемся продажей личной информации наших
          пользователей другим лицам. FITT делится личной информацией
          пользователей только в случаях, описанных ниже, и с дочерними или
          аффилированными компаниями FITT, которые либо подчиняются данному
          Заявлению о конфиденциальности, либо следуют практике, не менее
          защитной, чем та, которая описана в данном Заявлении о
          конфиденциальности. Перед тем, как FITT будет делиться этой
          информацией, мы предпримем необходимые меры для обеспечения того,
          чтобы личная информация наших пользователей получила достаточную
          защиту, как это требуется соответствующими законами о защите данных и
          внутренними политиками FITT.
          <br /> Поставщики услуг третьих сторон: Мы привлекаем другие компании
          и частных лиц для выполнения функций от нашего имени. Примеры включают
          анализ данных, предоставление помощи в маркетинге, обработку платежей,
          передачу контента, оценку и управление кредитными рисками, а также
          выполнение функций распознавания личности и других функций KYC. Эти
          поставщики услуг третьих сторон имеют доступ к личной информации и
          чувствительным персональным данным, необходимым для выполнения своих
          функций, но не могут использовать их в других целях. Кроме того, они
          должны обрабатывать личную информацию в соответствии с настоящим
          Уведомлением о конфиденциальности и только в пределах, разрешенных
          действующим законодательством о защите данных.
          <br /> Сделки с бизнесом: Поскольку мы продолжаем развивать наш
          бизнес, мы можем продавать или покупать другие предприятия или услуги.
          В таких сделках информация пользователей, как правило, является одним
          из передаваемых бизнес-активов, но остается подчиняться обещаниям,
          сделанным в любом действующем Заявлении о конфиденциальности. Также, в
          маловероятном случае, если FITT или практически все его активы будут
          приобретены, информация пользователей будет одним из передаваемых
          активов. <br /> Защита FITT и других: Мы предоставляем доступ к
          учетным записям и другой личной информации, когда считаем это
          необходимым для соблюдения закона или наших регуляторных обязательств;
          для применения или исполнения наших Условий использования и других
          соглашений; или для защиты прав, имущества или безопасности FITT,
          наших пользователей или других лиц. Это включает обмен информацией с
          другими компаниями и организациями для защиты от мошенничества и
          снижения кредитного риска, а также с регуляторными органами и
          правоохранительными структурами для выполнения законных запросов.
        </dd>
        <dt>5. Что насчет чувствительных данных?</dt>
        <dd>
          Мы не собираем чувствительные данные (также известные как специальные
          категории) через этот сайт или иным образом. В ограниченных случаях,
          когда мы действительно стремимся собрать такие данные, мы делаем это в
          соответствии с требованиями законов о защите данных и/или просим
          вашего согласия. <br />
          Термин "чувствительные данные" относится к различным категориям
          персональных данных, которые, как указано в законах о защите данных,
          требуют особого обращения, включая в некоторых случаях необходимость
          получения явного согласия от вас. К таким категориям относятся расовая
          или этническая принадлежность, политические взгляды, религиозные,
          философские или другие подобные убеждения, членство в профсоюзе,
          физическое или психическое здоровье, биометрические или генетические
          данные, сексуальная жизнь или ориентация, судимости и правонарушения
          (включая информацию о подозрении в преступной деятельности).
        </dd>
        <dt>6. Насколько безопасна моя информация?</dt>
        <dd>
          Мы разрабатываем наши системы, учитывая вашу безопасность и
          конфиденциальность. Мы защищаем вашу личную информацию в соответствии
          с действующим законодательством и нашими политиками в области защиты
          данных. Мы работаем над обеспечением безопасности вашей личной
          информации - такой как протоколы и программное обеспечение для
          шифрования при передаче данных - и поддерживаем соответствующие
          технические и организационные меры для защиты вашей личной информации
          от несанкционированной или неправомерной обработки и/или от случайной
          потери, изменения, раскрытия или доступа, или случайного или
          неправомерного уничтожения или повреждения.
          <br /> Мы обеспечиваем физическую, техническую и организационную
          защиту в рамках сбора, хранения и раскрытия вашей личной информации. У
          нас есть протоколы, контролирующие меры и соответствующие политики,
          процедуры и руководства для поддержания этих мер, учитывая риски,
          связанные с категориями персональных данных и обработкой, которую мы
          проводим. Мы применяем передовые меры безопасности для защиты ваших
          личных данных. Наши процедуры безопасности подразумевают, что мы можем
          попросить вас подтвердить свою личность, прежде чем предоставить вам
          личную информацию.
        </dd>
        <dt>7. Как FITT использует личные данные для маркетинговых целей?</dt>
        <dd>
          Мы можем передавать вашу личную информацию нашим маркетинговым
          партнерам для целей таргетирования, моделирования и/или аналитики, а
          также маркетинга и рекламы в соответствии с применимыми законами и
          правилами в области маркетинга. Вы можете воспользоваться своим правом
          остановить маркетинговые сообщения для вас, проверив вышеуказанные
          формы, описанные нами для сбора ваших личных данных, или используя
          механизмы отказа от подписки в электронных письмах, которые мы
          отправляем вам. Вы также можете отказаться от получения такого
          маркетинга, связавшись с support@fitt.ink. В таких случаях мы будем
          хранить минимальные личные данные, чтобы отметить, что вы отказались
          от подписки, чтобы избежать повторной связи с вами.
        </dd>
        <dt>
          8. Какие права у меня есть в отношении обработки моих личных данных
          ("Данных")?
        </dt>
        <dd>
          Вы имеете право (в обстоятельствах и при условиях, и с учетом
          исключений, указанных в применимом законодательстве) на: <br />
          Право на доступ: вы имеете право получить подтверждение об обработке
          ваших данных и получить копию этих данных, а также определенную
          информацию, связанную с их обработкой. <br />
          Право на исправление: вы можете запросить исправление своих данных,
          если они неточны, а также дополнить их. Вы также можете изменить свою
          личную информацию в своем аккаунте в любое время. <br /> Право на
          удаление: в некоторых случаях вы можете удалить свои данные. <br />
          Право возражать: вы можете возражать против обработки ваших данных по
          причинам, связанным с вашей ситуацией. Например, у вас есть право
          возражать против коммерческой проспекции. <br /> Право на ограничение
          обработки: в определенных обстоятельствах вы имеете право ограничить
          обработку своих данных. <br />
          Право на переносимость: в некоторых случаях вы можете попросить
          получить свои данные, которые вы предоставили нам, в
          структурированном, обычно используемом и машиночитаемом формате или,
          когда это возможно, чтобы мы напрямую передали ваши данные другому
          контролеру данных от вашего имени. <br /> Право отозвать ваше
          согласие: для обработки, требующей вашего согласия, вы имеете право
          отозвать свое согласие в любое время. Осуществление этого права не
          влияет на законность обработки на основе согласия, данного до отзыва
          последнего.
          <br />
          Право определить инструкции, касающиеся использования вашей личной
          информации после смерти: у вас есть право определить инструкции,
          касающиеся хранения, удаления и передачи вашей информации после вашей
          смерти. <br /> Право подать жалобы в соответствующий орган по защите
          данных. Если вы хотите воспользоваться любым из своих прав,
          пожалуйста, свяжитесь с нами по адресу tw@fitt.ink
        </dd>
        <dt>9. Какие варианты выбора у меня есть?</dt>
        <dd>
          Если у вас возникли вопросы или возражения по поводу того, как мы
          собираем и обрабатываем вашу личную информацию, пожалуйста, свяжитесь
          с pers@fitt.ink
        </dd>
        <dt>10. Как долго FITT хранит мою личную информацию?</dt>
        <dd>
          Мы будем хранить вашу личную информацию для обеспечения вашего
          непрерывного использования услуг FITT, настолько долго, насколько это
          необходимо для выполнения соответствующих целей, описанных в данном
          Уведомлении о конфиденциальности, как может быть предусмотрено
          законом, например, для налоговых и бухгалтерских целей, или как иначе
          сообщается вам. Кроме того, информация о личности и чувствительные
          личные данные могут храниться в течение восьми лет в соответствии с
          законами о борьбе с отмыванием денег.
        </dd>
        <dt>11. Контактная информация</dt>
        <dd>
          Наш сотрудник по защите данных может быть связан по адресу
          support@fitt.ink и будет работать над решением любых вопросов или
          проблем, которые у вас возникают в отношении сбора и обработки вашей
          личной информации. Пожалуйста, не стесняйтесь обращаться к нам, если:
          У вас есть общий вопрос о том, как FITT защищает вашу личную
          информацию. Вы хотите воспользоваться своими правами в отношении
          личных данных (как указано в разделах 8, 9, 10 и 11). Вы хотите подать
          жалобу на использование FITT ваших данных.
        </dd>
      </dl>
    </div>
  );
}
