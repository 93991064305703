import React from "react";

export default function Answer4() {
    return (
        <>
            <ol type="I" className="questions-inner-list">
                <li>
                    Дает начинающим трейдерам возможность изучать криптовалютный
                    рынок и приобретать необходимые навыки для трейдинга.
                </li>
                <li>
                    Помогает новичкам научиться торговать, анализируя действия
                    других, более опытных трейдеров.
                </li>
                <li>
                    Позволяет большему количеству трейдеров участвовать на
                    рынке, несмотря на нехватку времени для изучения рынка,
                    обычно необходимого для полноценного участия в трейдинге.
                </li>
                <li>
                    Создает сообщество из начинающих и опытных трейдеров,
                    которые могут обмениваться идеями, стратегиями и стремиться
                    совершенствовать свои сделки вместе.
                </li>
            </ol>
        </>
    );
}
